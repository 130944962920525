<template>
  <div class="base-store-component">
    <avue-crud
      ref="crud"
      v-model="Object.assign(searchForm, form)"
      :page="tablePage"
      :data="tableData"
      :tableLoading="tableLoading"
      :option="fillTableOption"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
    >
      <!-- <template #menuLeft="scoped">
        <BatchOperation :initData="{ shopType: form.shopType }" :sup_this="m_this" />
      </template> -->

      <template #shopName="scoped">
        <div style="position: relative">
          <InfoPart label="名称" :text="scoped.row.shopName" />
          <InfoPart v-if="scoped.row.shopType == 2" label="站点" :text="getWebSite(scoped.row.websiteAreaCode)" />
          <!-- <StatusTag type="danger" v-if="scoped.row.isEnabled == 0"> 已禁用 </StatusTag> -->
        </div>
      </template>
      <template #tagStatus="scoped">
        <div style="position: relative" v-if="scoped.row.isEnabled == 0">
          <StatusTag type="danger"> 已禁用 </StatusTag>
        </div>
      </template>
      <template #isAuthorized="scoped">
        {{ getLabel('isAuthorized', scoped.row.isAuthorized) }}
      </template>

      <template #isAutoSyncOrder="scoped">
        <div style="position: relative">
          <span v-if="scoped.row.isAutoSyncOrder == 1">是</span>
          <span v-else>否</span>
        </div>
      </template>

      <template #isAutoSyncWaybill="scoped">
        <div style="position: relative">
          <span v-if="scoped.row.isAutoSyncWaybill == 1">是</span>
          <span v-else>否</span>
        </div>
      </template>

      <template #mainAccountUid="scoped">
        <div style="position: relative">
          <span>{{ distributors[scoped.row.mainAccountUid] }}</span>
        </div>
      </template>

      <template #iossTaxNumber="scoped">
        <div style="position: relative">
          <InfoPart label="英国税号" :text="scoped.row.ukTaxNumber" />
          <InfoPart label="IOSS税号" :text="scoped.row.iossTaxNumber" />
        </div>
      </template>

      <template #menu="scoped">
        <Operation v-bind="permissionList" :initData="scoped.row" :sup_this="m_this" />
      </template>
    </avue-crud>
  </div>
</template>

<script>
import Operation from '../operation'
import BatchOperation from '../batchOperation'
import InfoPart from './module/infoPart'
import StatusTag from './module/statusTag'
import avueCrud from '@/mixins/avueCrud'
import { SHOP_TYPE_AMAZON, SHOP_TYPE_ALIBABA_EXPRESS, SHOP_TYPE_EBAY, SHOP_TYPE_OTHER } from '@/utils/constant'
import { amazonCol, alibabaExpressCol, ebayCol, otherCol } from './cols'
import { tableOption } from './const'
import { getShopList as getList } from '@/api/shop/manageApi'
import { mapGetters } from 'vuex'
import { checkPermission } from '@/utils/permission'

export default {
  mixins: [
    avueCrud({
      getList,
      tableOption
    })
  ],

  components: {
    Operation,
    BatchOperation,

    InfoPart,
    StatusTag
  },

  props: {
    form: {
      type: Object,
      require: true
    }
  },

  data() {
    return {
      searchForm: this.form,
      m_this: this,
      permissionList: {
        hasEidt: checkPermission('externaladmin:user:store:edit'),
        hasDisable: checkPermission('externaladmin:user:store:forbid'),
        hasEnable: checkPermission('externaladmin:user:store:enable'),
        hasDelete: checkPermission('externaladmin:user:store:delete'),
        hasOrderList: checkPermission('externaladmin:user:store:orderList')
      }
    }
  },
  computed: {
    ...mapGetters(['shop']),
    distributors() {
      let obj = {}
      this.shop.distributors.map((item) => {
        obj[item.id] = item.accountName
      })
      return obj
    },
    websiteAreaSiteDic() {
      return this.shop.websites
    },
    fillTableOption({ form }) {
      const tmpObj = {
        [SHOP_TYPE_AMAZON]: amazonCol,
        [SHOP_TYPE_ALIBABA_EXPRESS]: alibabaExpressCol,
        [SHOP_TYPE_EBAY]: ebayCol,
        [SHOP_TYPE_OTHER]: otherCol
      }
      let type = form.shopType > 4 ? 4 : form.shopType
      return { ...tableOption, column: tmpObj[type || 1] }
    }
  },
  mounted() {},
  methods: {
    getWebSite(val) {
      try {
        let result = JSON.parse(val)
        let codes = result?.countryIdList
        let countryNames = []
        codes.forEach((code) => {
          countryNames.push(
            this.websiteAreaSiteDic.find((item) => {
              return item.id == code
            })?.countryCnName
          )
        })
        return countryNames.join(' ')
      } catch (error) {
        return '暂无'
      }
    },

    afterInit() {}
  }
}
</script>

<style lang="scss">
.base-store-component {
  tr td:first-child {
    border-right: none;
  }
}
</style>
>
