import store from '@/store'

//亚马逊
export const amazonCol = [
  {
    label: '店铺名称',
    prop: 'shopName',
    search: true,
    width: 240,
    slot: true
  },
  {
    label: '',
    prop: 'tagStatus',
    search: false,
    width: 80,
    slot: true
  },
  {
    label: '店铺编码',
    prop: 'shopCode',
    search: true
  },

  {
    slot: true,
    label: '自动同步订单',
    prop: 'isAutoSyncOrder',
    dicData: store.getters.isAutoSyncOrder
  },

  {
    slot: true,
    label: '自动同步运单号',
    prop: 'isAutoSyncWaybill',
    dicData: store.getters.isAutoSyncWaybill
  },
  {
    hide: true,
    label: '绑定物流渠道',
    prop: 'expressCompanyId'
  },

  {
    hide: true,
    label: '税号设置',
    prop: 'iossTaxNumber',
    slot: true
  },
  {
    hide: false,
    label: '业务员',
    prop: 'mainAccountUid',
    slot: true
  },
  {
    label: '创建时间',
    prop: 'createTime',
    search: false,
    minWidth: 100,
    type: 'daterange',
    format: 'yyyy-MM-dd',
    valueFormat: 'yyyy-MM-dd',
    mock: {
      type: 'datetime',
      format: 'yyyy-MM-dd'
    }
  }
]

//速卖通
export const alibabaExpressCol = [
  {
    label: '店铺名称',
    prop: 'shopName',
    search: true,
    width: 240,
    slot: true
  },
  {
    label: '',
    prop: 'tagStatus',
    search: false,
    width: 80,
    slot: true
  },
  {
    label: '店铺编码',
    search: true,
    prop: 'shopCode'
  },
  {
    slot: true,
    label: '自动同步订单',
    prop: 'isAutoSyncOrder',
    dicData: store.getters.isAutoSyncOrder
  },

  {
    slot: true,
    label: '自动同步运单号',
    prop: 'isAutoSyncWaybill',
    dicData: store.getters.isAutoSyncWaybill
  },

  {
    label: '授权状态',
    prop: 'isAuthorized',
    slot: true,
    search: true,
    dicData: store.getters.isAuthorized
  },
  {
    hide: true,
    label: '绑定物流渠道',
    prop: 'expressCompanyId'
  },

  { hide: true, label: '税号设置', prop: 'iossTaxNumber', slot: true },
  {
    hide: false,
    label: '业务员',
    prop: 'mainAccountUid',
    slot: true
  },
  {
    label: '创建时间',
    prop: 'createTime',
    search: false,
    minWidth: 100,
    type: 'daterange',
    format: 'yyyy-MM-dd',
    valueFormat: 'yyyy-MM-dd',
    mock: {
      type: 'datetime',
      format: 'yyyy-MM-dd'
    }
  }
]

//ebay
export const ebayCol = [
  {
    label: '店铺名称',
    prop: 'shopName',
    width: 240,
    search: true,
    slot: true
  },
  {
    label: '',
    prop: 'tagStatus',
    search: false,
    width: 80,
    slot: true
  },
  {
    label: '店铺编码',
    prop: 'shopCode',
    search: true
  },

  {
    hide: true,
    label: '税号设置',
    prop: 'iossTaxNumber',
    slot: true
  },
  {
    hide: false,
    label: '业务员',
    prop: 'mainAccountUid',
    slot: true
  },
  {
    label: '创建时间',
    prop: 'createTime',
    search: false,
    minWidth: 100,
    type: 'daterange',
    format: 'yyyy-MM-dd',
    valueFormat: 'yyyy-MM-dd',
    mock: {
      type: 'datetime',
      format: 'yyyy-MM-dd'
    }
  }
]

//其他
export const otherCol = [
  {
    label: '店铺名称',
    prop: 'shopName',
    width: 240,
    search: true,
    slot: true
  },
  {
    label: '',
    prop: 'tagStatus',
    search: false,
    width: 80,
    slot: true
  },
  {
    label: '店铺编码',
    prop: 'shopCode',
    search: true
  },
  {
    hide: true,
    label: '税号设置',
    prop: 'iossTaxNumber',
    slot: true
  },
  {
    hide: false,
    label: '业务员',
    prop: 'mainAccountUid',
    slot: true
  },
  {
    label: '创建时间',
    prop: 'createTime',
    search: false,
    minWidth: 100,
    type: 'daterange',
    format: 'yyyy-MM-dd',
    valueFormat: 'yyyy-MM-dd',
    mock: {
      type: 'datetime',
      format: 'yyyy-MM-dd'
    }
  }
]
